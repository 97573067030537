import React from "react";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css"
import { CSVLink } from "react-csv";
import Particle from "./Particle";

const monday = mondaySdk();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      count: 0,
      loopstarted: false,
      name: "",
      start: "",
      end: ""
    };
    this.updateState = this.updateState.bind(this);
  }
  updateState(r) {
    this.setState({ boardData: r.data });
  }
  // 3317276518,3178765900,3006227584,2896506839,2852582042,2619385995,2502762307,2350437431,2223242988,2132294690,2132396873,2132397278,2132398380,2132400076,2748103799
  componentDidMount() {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    monday.setToken('eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE3NTkwMjg0NywidWlkIjozMTg2OTk3NSwiaWFkIjoiMjAyMi0wOC0xN1QxMDowMDozOS4zNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAwMDc4OTMsInJnbiI6InVzZTEifQ.19jFEU1Bw3tY9sas6pg_WT1FvPwfVZHaJnAOC13k2tI')
    monday.listen("context", async res => {
      // console.log(res.data, "-=-=-=-=-=-=-=-=-=-=-")
      if (this.state.loopstarted) return;
      this.setState({ loopstarted: true })
      for (let i = 1; i <= 1; i++) {
        this.setState({ context: res.data });
        await monday.api(
          `
          {
            board: boards(limit: 20, ids: [3317276518, 3178765900, 3006227584, 2896506839, 2852582042, 2619385995, 2502762307, 2350437431, 2223242988, 2132294690, 2132396873, 2132397278, 2132398380, 2132400076, 2748103799]) {
              name
              id
              tags {
                id
                name
              }
              items {
                id
                name
                created_at
                column_values {
                  title
                  value
                  text
                }
              }
            }
            lead: boards(ids: 2514925686) {
              name
              id
              items {
                name
                id
                created_at
                column_values {
                  title
                  value
                  text
                }
              }
            }
            query: boards(ids: 2723621873) {
              name
              id
              items {
                name
                id
                created_at
                column_values {
                  title
                  value
                  text
                }
              }
            }
            dailyBusinessActivities: boards(ids: 1897794662) {
              name
              id
              items {
                name
                id
                created_at
                column_values {
                  title
                  value
                  text
                }
              }
            }
          }
          
          `, { token: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE3NTE2ODAzNiwidWlkIjozMTkwMjA3OCwiaWFkIjoiMjAyMi0wOC0xMlQxMjo0NDoyNC44MDVaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTAwMDc4OTMsInJnbiI6InVzZTEifQ.DuXDe6uS3ev53RdgVV9ln3adY64gdwXOhOiCO8m7Xag' }
        )
          .then(async r => {
            if (r.error_code == "ComplexityException") {
              await sleep(30000);
              i = i - 1;
              console.log(i + "limit reached")
            }
            else {
              console.log(this.state.count)
              this.updateState(r);
              this.setState({ count: this.state.count + 1 })
            }
          })
      }
    });
  }
  render() {
    const boardArray = this.state.boardData;

    console.log(boardArray)
    let wonBoards = []
    let leadBoard = []
    let queryBoard = []
    let dailyBoard = []

    boardArray && boardArray.board.map((i) => {
      i.items.map((j) => {
        let auto_num, init, assm, prop, payment, sales_rep, t, est_no, pay_rec, ccd, cl, sources, industry, edv, adv, ia, poc, email, phone, pay_terms;
        j.column_values.map((idx) => {
          if (idx.title == 'Auto Number') {
            auto_num = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'POC' || idx.title == 'POC Name') {
            poc = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Email') {
            email = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Phone') {
            phone = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Sales Rep') {
            sales_rep = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Timeline') {
            t = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Estimate No.') {
            est_no = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Estimated Deal Value') {
            edv = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Actual Deal Value' || idx.title == 'Actual Value') {
            adv = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'In Account') {
            ia = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Payment Terms') {
            pay_terms = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Payment Received') {
            pay_rec = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Card Creation Date' || idx.title == 'Deal creation' ) {
            ccd = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Card Link' || idx.title == 'Card Creation Link') {
            cl = JSON.parse(idx.value);
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Sources') {
            sources = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Industry') {
            industry = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Initiation') {
            init = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Assessment') {
            assm = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Proposal') {
            prop = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Payment') {
            payment = idx.text;
          }
        })
        wonBoards.push({ bname: i.name, bid: i.id, tName: j.name, tid: j.id, an: auto_num ? auto_num : "NA", sr: sales_rep ? sales_rep : "NA", timeline: t ? t : "NA", edv: edv ? edv : "NA", adv: adv ? adv : "NA", e: est_no ? est_no : "NA", ia: ia ? ia : "NA", pr: pay_rec ? pay_rec : "NA", ccd: ccd ? ccd : "NA", cl: cl ? cl : "NA", src: sources ? sources : "NA", idty: industry ? industry : "NA", poc: poc? poc : "NA",email: email? email : "NA",phone: phone? phone : "NA",pt: pay_terms? pay_terms : "NA", payment:payment?payment : "NA", prop: prop? prop : "NA", assm: assm? assm : "NA", init: init? init : "NA"});
      })
    });

    boardArray && boardArray.lead.map((i) => {
      i.items.map((j) => {
        let sales_rep, stages, status, creation_date, forecasted_value, sources, industry, ia;

        j.column_values.map((idx) => {
          if (idx.title == 'Sales Rep') {
            sales_rep = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Stages') {
            stages = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Status') {
            status = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Creation Date') {
            creation_date = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Forecasted Value') {
            forecasted_value = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'In Account') {
            ia = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Sources') {
            sources = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Industry') {
            industry = idx.text;
          }
        })
        leadBoard.push({ bname: i.name, bid: i.id, tName: j.name, tid: j.id, sr: sales_rep ? sales_rep : "NA", stages: stages ? stages : "NA", status: status ? status : "NA", cr_d: creation_date ? creation_date : "NA", f_v: forecasted_value ? forecasted_value : "NA", ia: ia ? ia : "NA", src: sources ? sources : "NA", idty: industry ? industry : "NA" });
      })
    });


    boardArray && boardArray.query.map((i) => {
      i.items.map((j) => {
        let sales_rep, stages, status, creation_date, email, poc, industry, phone;

        j.column_values.map((idx) => {
          if (idx.title == 'Sales Rep') {
            sales_rep = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Initiation') {
            status = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Creation Date') {
            creation_date = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Email') {
            email = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Phone') {
            phone = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'POC') {
            poc = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Industry') {
            industry = idx.text;
          }
        })
        queryBoard.push({ bname: i.name, bid: i.id, tName: j.name, tid: j.id, sr: sales_rep ? sales_rep : "NA", status: status ? status : "NA", cr_d: creation_date ? creation_date : "NA", e: email ? email : "NA", p: phone ? phone : "NA", poc: poc ? poc : "NA", idty: industry ? industry : "NA" });
      })
    });
    console.log(wonBoards)
    console.log(queryBoard)
    console.log(leadBoard)

    boardArray && boardArray.dailyBusinessActivities.map((i) => {
      i.items.map((j) => {
        let dateOfEntry, estimate, timeTracking, type, status, sales_rep;

        j.column_values.map((idx) => {
          if (idx.title == 'Date of Entry') {
            dateOfEntry = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Estimate') {
            estimate = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Time Tracking') {
            timeTracking = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Type') {
            type = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Status') {
            status = idx.text;
          }
        })
        j.column_values.map((idx) => {
          if (idx.title == 'Sales Rep') {
            sales_rep = idx.text;
          }
        })
        dailyBoard.push({ dateOfEntry: dateOfEntry? dateOfEntry : "NA", estimate:  estimate? estimate:"NA", timeTracking: timeTracking?timeTracking:"NA", type:  type? type:"NA", status:  status? status:"NA", sales_rep:  sales_rep? sales_rep:"NA" });
      })
    });
    
    console.log(dailyBoard)
    const headers = [
      { label: "Board Name", key: "bname" },
      { label: "Board ID", key: "bid" },
      { label: "Task", key: "tName" },
      { label: "Task ID", key: "tid" },
      { label: "Auto Number", key: "an" },
      { label: "Sales Rep", key: "sr" },
      { label: "Timeline From", key: "timeline.from" },
      { label: "Timeline To", key: "timeline.to" },
      { label: "Estimated Deal Value", key: "edv" },
      { label: "Estimate No.", key: "e" },
      { label: "Actual Deal Value", key: "adv" },
      { label: "In Account", key: "ia" },
      { label: "Payment Received", key: "pr" },
      { label: "Card Creation Date", key: "ccd" },
      { label: "Card Link", key: "cl" },
      { label: "POC", key: "poc" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Payment Terms", key: "pt" },
      { label: "Payment", key: "payment" },
      { label: "Proposal", key: "prop" },
      { label: "Industry", key: "idty" },
      { label: "Assessment", key: "assm" },
      { label: "Initiation", key: "init" },
    ];
    const headersLead = [
      { label: "Board Name", key: "bname" },
      { label: "Board ID", key: "bid" },
      { label: "Task", key: "tName" },
      { label: "Task ID", key: "tid" },
      { label: "Sales Rep", key: "sr" },
      { label: "Stages", key: "stages" },
      { label: "Status", key: "status" },
      { label: "Creation Date", key: "cr_d" },
      { label: "Forecasted value", key: "f_v" },
      { label: "In Account", key: "ia" },
      { label: "Sources", key: "src" },
      { label: "Industry", key: "idty" }
    ];
    const headersQuery = [
      { label: "Board Name", key: "bname" },
      { label: "Board ID", key: "bid" },
      { label: "Task", key: "tName" },
      { label: "Task ID", key: "tid" },
      { label: "Sales Rep", key: "sr" },
      { label: "POC", key: "poc" },
      { label: "Initiation", key: "status" },
      { label: "Creation Date", key: "cr_d" },
      { label: "Email", key: "e" },
      { label: "Phone", key: "p" },
      { label: "Sources", key: "src" },
      { label: "Industry", key: "idty" }
    ];
    const headersDaily = [
      { label: "Date of Entry", key: "dateOfEntry" },
      { label: "Estimate", key: "estimate" },
      { label: "Time Tracking", key: "timeTracking" },
      { label: "Type", key: "type" },
      { label: "Status", key: "status" },
      { label: "Sales Rep", key: "sales_rep" },
    ];
    let csvWon = {
      data: wonBoards,
      headers: headers,
      filename: "won.csv",
    };
    let csvLead = {
      data: leadBoard,
      headers: headersLead,
      filename: "leadBoard.csv",
    };
    let csvQuery = {
      data: queryBoard,
      headers: headersQuery,
      filename: "queryBoard.csv",
    };
    let csvDailyBoard = {
      data: dailyBoard,
      headers: headersDaily,
      filename: "DailyBusinessBoard.csv",
    };
    return (
      <div className="App">
        <section className="max-w-screen-xl py-10 mx-auto px-6">
          <div className="flex flex-col justify-center items-center pt-5 pb-10 rounded-3xl shadow-2xl shadow-cyan-500">
            <img src="http://cdn.shopify.com/s/files/1/0587/1049/1306/files/marmeto-logo.jpg?v=1653605388" width={190}></img>
            <h1 className="poppins text-2xl text-center pb-10">Monday Sales Dashboard</h1>
            <div className="flex flex-col gap-10 justify-center items-center">
              <span className="flex flex-row w-96 justify-between"><p className="poppins text-xl text-center pt-2">Won Board</p><button className="btn-primary w-48 text-white bg-cyan-500 shadow-xl shadow-cyan-500/50 h-12 rounded-xl hover:bg-white hover:text-black hover:shadow-xl hover:shadow-black-300 "><CSVLink className="btntxt" {...csvWon}>Download</CSVLink></button></span>
              <span className="flex flex-row w-96 justify-between"><p className="poppins text-xl text-center pt-2">Query Board</p><button className="btn-primary w-48 text-white bg-cyan-500 shadow-xl shadow-cyan-500/50 h-12 rounded-xl hover:bg-white hover:text-black hover:shadow-xl hover:shadow-black-300"><CSVLink className="btntxt" {...csvQuery}>Download</CSVLink></button></span>
              <span className="flex flex-row w-96 justify-between"><p className="poppins text-xl text-center pt-2">Lead Board</p><button className="btn-primary w-48 text-white bg-cyan-500 shadow-xl shadow-cyan-500/50 h-12 rounded-xl hover:bg-white hover:text-black hover:shadow-xl hover:shadow-black-300"><CSVLink className="btntxt" {...csvLead}>Download</CSVLink></button></span>
              <span className="flex flex-row w-96 justify-between"><p className="poppins text-xl text-center pt-2">Daily Business Board</p><button className="btn-primary w-48 text-white bg-cyan-500 shadow-xl shadow-cyan-500/50 h-12 rounded-xl hover:bg-white hover:text-black hover:shadow-xl hover:shadow-black-300"><CSVLink className="btntxt" {...csvDailyBoard}>Download</CSVLink></button></span>
              <button className="btn-primary w-48 text-white bg-cyan-500 shadow-xl shadow-cyan-500/50 h-12 rounded-xl hover:bg-white hover:text-black hover:shadow-xl hover:shadow-black-300" onClick={() => window.location.reload(false)}>Reset</button>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default App;
